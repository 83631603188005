module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milka","short_name":"Milka","start_url":"/","background_color":"#FFFFFF","theme_color":"#7D69AC","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93eeea44505df97a2cf6562e81126e32"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://9eb4e1dc9bad4c2f6c0a6ed4c2611fc8@o215997.ingest.sentry.io/4506757213847552","sampleRate":1,"environment":"develop","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
